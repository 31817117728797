import Element from "../../domain/Element/Element";
import ElementGatewayInterface from "../../domain/Element/ElementGatewayInterface";
import envVariable from "../../presentation/util/envVariable";
import Caller from "../../domain/Api/Caller";
import FetchVoisinMockedElementGateway from "./FetchVoisinMockedElementGateway";
import FetchSogenialMockedElementGateway from "./FetchSogenialMockedElementGateway";
import FetchRemakeMockedElementGateway from "./FetchRemakeMockedElementGateway";
import FetchSwisslifeMockedElementGateway from "./FetchSwisslifeMockedElementGateway";

const VIAGER_RATES_TABLE_ATTRIBUTES_OPTIONS = {
    header: ["Age de l'usufruitier", "Usufruit", "Nue-propriété"],
    options: [
        {
            value: "20",
            label: "Moins de 20 ans révolus",
            options: [{label: "90%", value: "90"}, {label: "10%", value: "10"}],
            presentation: "Moins de 20 ans révolus"
        },
        {
            value: "21_30",
            label: "De 21 à 30 ans",
            options: [{label: "80%", value: "80"}, {label: "20%", value: "20"}],
            presentation: "De 21 à 30 ans"
        },
        {
            value: "31_40",
            label: "De 31 à 40 ans",
            options: [{label: "70%", value: "70"}, {label: "30%", value: "30"}],
            presentation: "De 31 à 40 ans"
        },
        {
            value: "41_50",
            label: "De 41 à 50 ans",
            options: [{label: "60%", value: "60"}, {label: "40%", value: "40"}],
            presentation: "De 41 à 50 ans"
        },
        {
            value: "51_60",
            label: "De 51 à 60 ans",
            options: [{label: "50%", value: "50"}, {label: "50%", value: "50"}],
            presentation: "De 51 à 60 ans"
        },
        {
            value: "61_70",
            label: "De 61 à 70 ans",
            options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
            presentation: "De 61 à 70 ans"
        },
        {
            value: "71_80",
            label: "De 71 à 80 ans",
            options: [{label: "30%", value: "30"}, {label: "70%", value: "70"}],
            presentation: "De 71 à 80 ans"
        },
        {
            value: "81_90",
            label: "De 81 à 90 ans",
            options: [{label: "20%", value: "20"}, {label: "80%", value: "80"}],
            presentation: "De 81 à 90 ans"
        },
        {
            value: "91",
            label: "Plus de 91 ans",
            options: [{label: "10%", value: "10"}, {label: "90%", value: "90"}],
            presentation: "Plus de 91 ans"
        },
    ]
}

export default class FetchElementGateway implements ElementGatewayInterface {

    /**
     * Returns Mocked or not Elements if mentioned by customer specifics mock
     * @param blockId
     * @param dossierId
     * @param customer
     * @param productCode
     */
    async getElementsByBlockId(
        blockId: string,
        dossierId: string,
        customer: string,
        productCode: string|null
    ): Promise<Element[] | null> {

        let path = `${envVariable("REACT_APP_API_URL")}/${envVariable("REACT_APP_LOCALE")}`
        dossierId && (path += `/dossier/${dossierId}`)
        path += `/blocks/${blockId}/elements`

        return Caller.executeGet(path, {}).then(
            data => {
                if (!data) {
                    return null
                }
                const jsonFormData = localStorage.getItem('form')
                const formData = jsonFormData ? JSON.parse(jsonFormData) : null
                let mokingObject
                switch (customer) {
                    case 'voisin':
                        mokingObject = new FetchVoisinMockedElementGateway()
                        break
                    case 'sogenial':
                        mokingObject = new FetchSogenialMockedElementGateway()
                        break
                    case 'remake':
                        mokingObject = new FetchRemakeMockedElementGateway()
                        break
                    case 'swisslife-am':
                        mokingObject = new FetchSwisslifeMockedElementGateway()
                        break
                    default:
                        break
                }
                return mokingObject
                    ? mokingObject.get(data.elements, productCode, formData)
                    : null
            }
        ).catch(() => null);
    }

}
