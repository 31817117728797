import ILifeRateType from "./ILifeRateType";
import {
    IOptionOptionsType,
    IOptionType
} from "../../../presentation/component/elements/array-choice/IOptionType";

export default class LifeRateDataTransformer {

    transform(rates: ILifeRateType[]|undefined):IOptionType {
        const optionsValue =  rates?.map(
            (rate: ILifeRateType): IOptionOptionsType => ({
                value: String.prototype.concat(
                    0 != rate.min_age ? rate.min_age.toString().concat('_') : '',
                    0 != rate.max_age ? rate.max_age.toString() : ''
                ),
                label: rate.rate_label,
                presentation: rate.rate_label,
                options: [
                    {
                        label: rate.usufructuary_rate.toString().concat(' %'),
                        value: rate.usufructuary_rate.toString()
                    },
                    {
                        label: rate.bare_ownership_rate.toString().concat(' %'),
                        value: rate.bare_ownership_rate.toString()
                    }
                ],
            })
        )
        return {
            header: ["Age de l'usufruitier", "Usufruit", "Nue-propriété"],
            options: optionsValue,
        }
    }
}