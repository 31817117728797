import Element from "../../domain/Element/Element";
import {EElementType} from "../../domain/Element/EElementType";

const SOGENIAL_IBAN_ELEMENT_ID = "d72b0453-bbe6-4a6c-96be-47024fa56d5f";

const SOGENIAL_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID = "6b584ecc-d751-4627-912b-acdf7dc8e093";
const SOGENIAL_FULL_OWNERSHIP_DISMEMBERMENT_SUBSCRIPTION_DETENTION_MODE = "Pleine propriété";
const SOGENIAL_KNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie connue";
const SOGENIAL_UNKNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie inconnue";

const SOGENIAL_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID = "8ca72336-f59f-46c6-bcb4-0e3256bbd237";
const SOGENIAL_TEMPORARY_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Temporaire";
const SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Viager";

const SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID = "d918aff9-4005-4354-939b-6d15c2396b12";
const SOGENIAL_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "632b763d-42c5-4b92-a97a-106cc66634fd";
const SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "a0130dd5-ba96-4647-8088-9004028588d5";
const SOGENIAL_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "52ac6c96-0781-4773-9896-bc39784593ab";

const SOGENIAL_PRODUCT_CODE_FOR_CDV = "CŒUR DE VILLE";
const SOGENIAL_PRODUCT_CODE_FOR_CDE = "CŒUR D'EUROPE";
const SOGENIAL_PRODUCT_CODE_FOR_CDR = "CŒUR DE REGIONS";

const SOGENIAL_CDV_PART_UNIT_PRICE_ELEMENT_ID = "0b73f4f8-ab0f-4e11-9bb0-9fb9cf43ab42";
const SOGENIAL_CDE_PART_UNIT_PRICE_ELEMENT_ID = "12ad6818-ba4e-433f-a5b6-605769ab5b39";
const SOGENIAL_CDR_PART_UNIT_PRICE_ELEMENT_ID = "946eade7-5600-4874-a076-9da673e49719";

const SOGENIAL_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID = "eb075c2b-bc0b-4315-9a4b-fb097abbbeb6"
const SOGENIAL_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "a39d2179-53b8-442d-98a2-3b708c8f7ff4"
const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID = "bdb19ec2-5316-45df-835b-3f6b8a4eafbe"
const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID = "7487d531-6b72-488a-b429-e783f012cddf"
const SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID = "fad5d6f1-3797-4d98-8a3e-841c92d44fe4"

const SOGENIAL_BARE_OWNERSHIP_RATE_ELEMENT_ID = "0258773a-ea75-4f37-a017-9572556c64fe"
const SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID = "953d482d-db54-4915-b6b1-e91eb2d1d8b9"
const SOGENIAL_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = "41171942-7972-4d9f-9506-c5329e6449b5"

const SOGENIAL_USUFRUCT_RATE_ELEMENT_ID = "a5031694-59e1-4d3c-a688-01ea3f4bf52e"
const SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID = "11977206-c7ed-4ddd-ab13-4107eba2e121"
const SOGENIAL_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"


export default class FetchSogenialMockedElementGateway {

    get(elements, productCode: string, formData): Promise<Element[] | null> {


        const sogenialSubscriptionDetentionMode = formData
            ? formData[SOGENIAL_SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID]
            : null

        const sogenialSubscriptionType = formData
            ? formData[SOGENIAL_SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID]
            : null

        const lifeDismemberment = (sogenialSubscriptionType === SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)
        const temporaryDismemberment = (sogenialSubscriptionType !== SOGENIAL_LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)

        let CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = ''
        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        lifeDismemberment && (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_LIFE_DISMEMBERMENT_RATES_ELEMENT_ID)

        switch (productCode) {
            case SOGENIAL_PRODUCT_CODE_FOR_CDV:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDV_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID)
                break
            case SOGENIAL_PRODUCT_CODE_FOR_CDE:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDE_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID)
                break
            case SOGENIAL_PRODUCT_CODE_FOR_CDR:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = SOGENIAL_CDR_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID)
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = chosenProductUnitPriceElement !== undefined
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (item) => {

                let type = item.type
                let attributes = item.attributes
                const condition = item.condition
                let calculate = ''
                let reference = ''

                switch (item.id) {

                    // ............................................................................................ IBAN
                    case SOGENIAL_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // ..................................................................... Tableau de démembrement CDV
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDV_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDV) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '12.50%', value: '12.5'}, {label: '87.50%', value: '87.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '16.00%', value: '16'}, {label: '84.00%', value: '84'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '20.00%', value: '20'}, {label: '80.00%', value: '80'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '23.50%', value: '23.5'}, {label: '76.50%', value: '76.5'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '27.00%', value: '27'}, {label: '73.00%', value: '73'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '28.50%', value: '28.5'}, {label: '71.50%', value: '71.5'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '30.00%', value: '30'}, {label: '70.00%', value: '70'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '33.00%', value: '33'}, {label: '67.00%', value: '67'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '34.50%', value: '34.5'}, {label: '65.50%', value: '65.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '36.00%', value: '36'}, {label: '64.00%', value: '64'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '37.00%', value: '37'}, {label: '63.00%', value: '63'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '38.00%', value: '38'}, {label: '62.00%', value: '62'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '39.00%', value: '39'}, {label: '61.00%', value: '61'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '40.00%', value: '40'}, {label: '60.00%', value: '60'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '41.00%', value: '41'}, {
                                            label: '59.00%', value: '59'
                                        }],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '43.00%', value: '43'}, {label: '57.00%', value: '57'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '44.00%', value: '44'}, {label: '56.00%', value: '56'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ..................................................................... Tableau de démembrement CDE
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDE_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDE) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '12.50%', value: '12.5'}, {label: '87.50%', value: '87.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '15.00%', value: '15'}, {label: '85.00%', value: '85'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '20.00%', value: '20'}, {label: '80.00%', value: '80'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '22.00%', value: '22'}, {label: '78.00%', value: '78'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '25.00%', value: '25'}, {label: '75.00%', value: '75'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '27.50%', value: '27.5'}, {label: '72.50%', value: '72.5'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '30.00%', value: '30'}, {label: '70.00%', value: '70'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '32.00%', value: '32'}, {label: '68.00%', value: '68'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '34.00%', value: '34'}, {label: '66.00%', value: '66'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '35.50%', value: '35.5'}, {label: '64.50%', value: '64.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '36.00%', value: '36'}, {label: '64.00%', value: '64'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '37.00%', value: '37'}, {label: '63.00%', value: '63'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '38.50%', value: '38.5'}, {label: '61.50%', value: '61.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '39.00%', value: '39'}, {label: '61.00%', value: '61'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '40.00%', value: '40'}, {label: '60.00%', value: '60'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '41.00%', value: '41'}, {label: '59.00%', value: '59'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '42.50%', value: '42.5'}, {label: '57.50%', value: '57.5'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ..................................................................... Tableau de démembrement CDR
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_CDR_RATES_ELEMENT_ID:
                        if (productCode !== SOGENIAL_PRODUCT_CODE_FOR_CDR) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Usufruit', 'Nue-propriété'],
                                options: [
                                    {
                                        value: '3',
                                        label: '3 ans',
                                        options: [{label: '13.50%', value: '13.5'}, {label: '86.50%', value: '86.5'}],
                                        presentation: '3 ans'
                                    },
                                    {
                                        value: '4',
                                        label: '4 ans',
                                        options: [{label: '17.50%', value: '17.5'}, {label: '82.50%', value: '82.5'}],
                                        presentation: '4 ans'
                                    },
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '23.00%', value: '23'}, {label: '77.00%', value: '77'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '26.00%', value: '26'}, {label: '74.0%', value: '74'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '28.00%', value: '28'}, {label: '72.00%', value: '72'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '31.00%', value: '31'}, {label: '69.00%', value: '69'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '34.00%', value: '34'}, {label: '66.00%', value: '66'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '36.50%', value: '36.5'}, {label: '63.50%', value: '63.5'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '37.50%', value: '37.5'}, {label: '62.50%', value: '62.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '38.50%', value: '38.5'}, {label: '61.50%', value: '61.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '39.50%', value: '39.5'}, {label: '60.50%', value: '60.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '40.50%', value: '40.5'}, {label: '59.50%', value: '59.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '42.00%', value: '42'}, {label: '58.00%', value: '58'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '43.00%', value: '43'}, {label: '57.00%', value: '57'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '44.00%', value: '44'}, {label: '56.00%', value: '56'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '45.00%', value: '45'}, {label: '55.00%', value: '55'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '45.50%', value: '45.5'}, {label: '54.50%', value: '54.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '46.00%', value: '46'}, {label: '54.00%', value: '54'}],
                                        presentation: '20 ans'
                                    },
                                ]
                            },
                        }
                        break

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case SOGENIAL_PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID
                        break

                    // ............................................................................ Taux Nu-Propriétaire
                    case SOGENIAL_BARE_OWNERSHIP_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break

                    // ......................................................................... Montant Nu-Propriétaire
                    case SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // .................................................. Montant Nu-Propriétaire : ( en toutes lettres)
                    case SOGENIAL_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID
                        break

                    // ................................................................................ Taux Usufruitier
                    case SOGENIAL_USUFRUCT_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // ............................................................................. Montant Usufruitier
                    case SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        calculate += ` / 100) * (${chosenProductUnitPrice}`
                        calculate += `* <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>)`
                        break

                    // ...................................................... Montant Usufruitier : ( en toutes lettres)
                    case SOGENIAL_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_USUFRUCT_AMOUNT_ELEMENT_ID
                        break

                    // ........................................................................... Durée de démembrement
                    case SOGENIAL_TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    // .................................................................................. Montant global
                    case SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                        calculate = `${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${SOGENIAL_PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ........................................................... Montant global : ( en toutes lettres)
                    case SOGENIAL_PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = SOGENIAL_PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )
    }
}