import React, {useState, useRef, FC, KeyboardEvent, ChangeEvent, InputHTMLAttributes, FormEvent, useEffect} from 'react'
import {IFormFieldProps} from "../../IFormFieldProps";
import Input from "./Input";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps, InputProps {
    id?: string
    classes: string,
    value?: string,
    defaultValue?: string
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    clearErrors: (id: string | undefined) => void
}

type InputProps = {
    isUppercase?: boolean
}

const BICCodeInput: FC<IProps> = (
    {
        id,
        classes,
        label,
        onChange,
        value,
        defaultValue,
        register,
        clearErrors,
        error,
        required,
        isUppercase,
        ...rest
    }
) => {

    value = undefined !== value ? value : ''

    const [valBank, setBankCodeValue] = useState<string>(value.slice(0, 4))
    const [valCountry, setCountryCodeValue] = useState<string>(value.slice(4, 6))
    const [valLocal, setLocationCodeValue] = useState<string>(value.slice(6, 8))
    const [valOptional, setValOptional] = useState<string>(value.slice(8, 11))

    const valBankRef = useRef<HTMLInputElement>(null)
    const valCountryRef = useRef<HTMLInputElement>(null)
    const valLocalRef = useRef<HTMLInputElement>(null)
    const valOptionalRef = useRef<HTMLInputElement>(null)

    const fullValue: string = valBank + valCountry + valLocal + valOptional

    useEffect(() => {
        if ('' === value && defaultValue) {
            setBankCodeValue(defaultValue.slice(0, 4))
            setCountryCodeValue(defaultValue.slice(4, 6))
            setLocationCodeValue(defaultValue.slice(6, 8))
            setValOptional(defaultValue.slice(8, 11))
        }
    }, [value, defaultValue])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'valBank': {
                if (e.currentTarget.value.length <= 4) {
                    setBankCodeValue(e.currentTarget.value)
                    break
                }
                valCountryRef.current?.focus()
                setCountryCodeValue(e.currentTarget.value.slice(-1))
                break
            }
            case 'valCountry': {
                if (e.currentTarget.value.length <= 2) {
                    setCountryCodeValue(e.currentTarget.value)
                    break
                }
                valLocalRef.current?.focus()
                setLocationCodeValue(e.currentTarget.value.slice(-1))
                break
            }
            case 'valLocal': {
                if (e.currentTarget.value.length <= 2) {
                    setLocationCodeValue(e.currentTarget.value)
                    break
                }
                valOptionalRef.current?.focus()
                setValOptional(e.currentTarget.value.slice(-1))
                break
            }
            case 'valOptional': {
                if (e.currentTarget.value.length <= 3) {
                    setValOptional(e.currentTarget.value)
                    break
                }
                break
            }
            default:
                return
        }
    }

    const keyDownHandler = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement
        if (e.key === 'Backspace') {
            switch (target.id) {
                case 'valCountry': {
                    if (!target.value.length) valBankRef.current?.focus()
                    break
                }
                case 'valLocal': {
                    if (!target.value.length) valCountryRef.current?.focus()
                    break
                }
                case 'valOptional': {
                    if (!target.value.length) valLocalRef.current?.focus()
                    break
                }
                default:
                    return
            }
        }
    }
    const {t} = useTranslation()

    return (
        <div className={`${classes} ${rest.readonly && "readonly-element"}`}>
            <div className={`form-floating`}>
                <div className={`bic-code-split`} title={rest.help}>
                    <div className={`bic-code-split-label`}>
                        <label className={``}>{parse(undefined !== label ? label : '')}</label>
                    </div>
                    <div className={`bic-code-split-content row center-md`}>
                        <div className={`bic-code-split-content-portion col-xs-12 col-sm-6 col-md-3`}>
                            <label className="row center-xs label-top" htmlFor="valBank">
                                <div className={`col-md-12 label-text`}>{t('bic-code.bank-code')}</div>
                                <div className={`col-md-12 label-arrow`}>&#8595;</div>
                            </label>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valBankRef}
                                    value={!isUppercase ? valBank : valBank.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valBank"
                                    name="valBank"
                                    title={t('bic-code.bank-code-help')}
                                />
                            </div>
                        </div>
                        <div className={`bic-code-split-content-portion col-xs-12 col-sm-6 col-md-3`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valCountryRef}
                                    value={!isUppercase ? valCountry : valCountry.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valCountry"
                                    name="valCountry"
                                    title={t('bic-code.country-code-help')}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valCountry">
                                <div className={`col-md-12 label-arrow`}>&#8593;</div>
                                <div className={`col-md-12 label-text`}>{t('bic-code.country-code')}</div>
                            </label>
                        </div>
                        <div className={`bic-code-split-content-portion col-xs-12 col-sm-6 col-md-3`}>
                            <label className="row center-xs label-top" htmlFor="valLocal">
                                <div className={`col-md-12 label-text`}>{t('bic-code.localization-code')}</div>
                                <div className={`col-md-12 label-arrow`}>&#8595;</div>
                            </label>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valLocalRef}
                                    value={!isUppercase ? valLocal : valLocal.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valLocal"
                                    name="valLocal"
                                    title={t('bic-code.localization-code-help')}
                                />
                            </div>
                        </div>
                        <div className={`bic-code-split-content-portion col-xs-12 col-sm-6 col-md-3`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valOptionalRef}
                                    value={!isUppercase ? valOptional : valOptional.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valOptional"
                                    name="valOptional"
                                    title={t('bic-code.subsidiary-option-code-help')}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valOptional">
                                <div className={`col-md-12 label-arrow`}>&#8593;</div>
                                <div className={`col-md-12 label-text`}>{t('bic-code.subsidiary-option-code')}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <Input
                    classes={`absolute`}
                    register={register}
                    clearErrors={clearErrors}
                    type={'hidden'}
                    error={error}
                    id={id}
                    name={id}
                    required={required}
                    readonly={false}
                    value={fullValue}
                    defaultValue={defaultValue}
                />
            </div>

        </div>
    )
}

export default BICCodeInput
