import {FunctionComponent, useEffect, useState} from 'react';
import {IArrayChoiceProps} from './IArrayChoiceProps';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import Element from '../../../../domain/Element/Element';
import envVariable from "../../../util/envVariable";
import FieldErrorMessage from '../messages/FieldErrorMessage';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import parse from "html-react-parser";
import FetchRemakeMockedElementGateway from "../../../../gateway/Element/FetchRemakeMockedElementGateway";
import FetchVoisinMockedElementGateway from "../../../../gateway/Element/FetchVoisinMockedElementGateway";

/**
 * Returns a Lines and Columns Table HTML Representation From given Array Values
 * @param options
 * @param id
 * @param defaultValue
 * @param classes
 * @param register
 * @param name
 * @param error
 * @param variant
 * @param rest
 * @constructor
 */
const LifeDismembermentArrayChoice: FunctionComponent<IArrayChoiceProps> = (
    {
        id,
        name,
        defaultValue,
        options,
        classes,
        register,
        error,
        ...rest
    }
) => {

    interface ICustomerElementIds {
        [key: string]: string
    }

    const dispatch = useAppDispatch()

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    const [selectedDismembermentRateAgeValue, setSelectedDismembermentRateAgeValue] = useState(defaultValue ?? '');

    useEffect(
        () => {
            const itemSelected: HTMLElement = document.querySelector('.array-choice--item--selected') as HTMLElement
            if (selectedDismembermentRateAgeValue !== '' && itemSelected) {
                setTimeout(
                    function () {
                        itemSelected.click()
                    },
                    500
                )
            }
        },
        [selectedDismembermentRateAgeValue]
    )

    /**
     * Manage Table Column Click Event
     * @param element
     */
    const handleClick = (
        element
    ) => {

        const formGateway = new FormGateway()
        const selectedDismembermentRateIndex = element.currentTarget.attributes.getNamedItem('data-key').value
        const selectedDismembermentRateAgeValue = element.currentTarget.attributes.getNamedItem('data-value').value

        const selectedDismembermentRateData = [];
        selectedDismembermentRateData[id] = selectedDismembermentRateAgeValue;
        const optionsOptions = options?.options
        optionsOptions && optionsOptions[selectedDismembermentRateIndex].options?.map(
            (opt, index) => {
                selectedDismembermentRateData[`${id}_${index}`] = opt.value;
            }
        )


        const lifeDismembermentAgeElementsIds: ICustomerElementIds = {
            voisin: FetchVoisinMockedElementGateway.lifeDismembermentRateAgeElementId,
            remake: FetchRemakeMockedElementGateway.lifeDismembermentRateAgeElementId,
            sogenial: 'a39d2179-53b8-442d-98a2-3b708c8f7ff4',
            euryale: '',
            'swisslife-am': '',
        }

        const customer = envVariable('REACT_APP_CUSTOMER')
        const ageElementId: string = lifeDismembermentAgeElementsIds[customer] ?? ''
        const ageValue: string = formGateway.getValueForElementId(ageElementId.toString()) ?? ''

        // ............................................ Clearing amounts in letters dismemberment rate related  elements
        if (
            '' !== selectedDismembermentRateAgeValue
            &&
            ageValue !== selectedDismembermentRateAgeValue
        ) {
            let bareOwnershipAmountInLettersElementId
            let usufructAmountInLettersElementId
            switch (customer) {
                case 'voisin':
                    bareOwnershipAmountInLettersElementId = FetchVoisinMockedElementGateway.lifeDismembermentBareOwnershipAmountInLettersElementId
                    usufructAmountInLettersElementId = FetchVoisinMockedElementGateway.lifeDismembermentUsufructAmountInLettersElementId
                    break
                case 'remake':
                    bareOwnershipAmountInLettersElementId = FetchRemakeMockedElementGateway.lifeDismembermentBareOwnershipAmountInLettersElementId;
                    usufructAmountInLettersElementId = FetchRemakeMockedElementGateway.lifeDismembermentUsufructAmountInLettersElementId
                    break
                case 'sogenial':
                case 'euryale':
                case 'swisslife-am':
                    break
            }

            const amountInLettersElementsNames = bareOwnershipAmountInLettersElementId && usufructAmountInLettersElementId
                ? [bareOwnershipAmountInLettersElementId, usufructAmountInLettersElementId]
                : []

            const amountInLettersElements: NodeListOf<HTMLElement>[] = amountInLettersElementsNames.map(
                (elementName) => {
                    return document.getElementsByName(elementName)
                }
            )

            amountInLettersElements.forEach(
                (elementNodes: NodeListOf<HTMLElement>) => {
                    const amountInLetterHTMLInputElement = elementNodes[0] as HTMLInputElement
                    amountInLetterHTMLInputElement.value = ''
                }
            )
        }


        const saveUseCase = new SaveUseCase(formGateway)
        saveUseCase.execute(selectedDismembermentRateData).then(
            () => {
                const elementsIdToRender: string[] = getElementsIdToRender(
                    elementsWithCondition,
                    id,
                    elementsWithCalcule,
                    elementsWithReference
                )
                elementsIdToRender.map(
                    (elementIdToRender) => {
                        dispatch(updateElementTimestamp({'id': elementIdToRender}))
                    }
                )
            }
        )

        setSelectedDismembermentRateAgeValue(selectedDismembermentRateAgeValue)

    };

    const readOnlyClassName = rest.readonly ? 'readonly-element' : ''

    return (
        <div className={classes}>
            <input type="hidden" name={id} id={name} ref={register} value={selectedDismembermentRateAgeValue}/>
            <label htmlFor={id}>{parse(rest.label ?? '')}</label>
            <div className={readOnlyClassName} title={rest.help}>
                <div className="array-choice">
                    <div className="array-choice__header">
                        {
                            options?.header?.map(
                                (title, index) =>
                                    <div className="array-choice--item__heading" key={index}>
                                        {title}
                                    </div>
                            )
                        }
                    </div>
                    <div className="array-choice__options">
                        {
                            options?.options?.map(
                                (option, index) => {
                                    const className = option.value === selectedDismembermentRateAgeValue
                                        ? 'array-choice--item array-choice--item--selected'
                                        : 'array-choice--item'
                                    return <div
                                        className={className}
                                        key={index}
                                        data-key={index}
                                        data-value={option.value}
                                        onClick={handleClick}
                                    >
                                        <div className="array-choice--item__heading">{option.label}</div>
                                        {
                                            option?.options?.map(
                                                (optionChild, indexOption) =>
                                                    <div className="array-choice--item__option" key={indexOption}>
                                                        {optionChild.label}
                                                    </div>
                                            )
                                        }
                                    </div>

                                }
                            )
                        }
                    </div>
                </div>
                {
                    error?.message && <FieldErrorMessage message={error.message}/>
                }
            </div>
        </div>
    )
};

export default LifeDismembermentArrayChoice;
