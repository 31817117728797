import Element from "../../domain/Element/Element";
import {EElementType} from "../../domain/Element/EElementType";
import {EDocusignButton} from "../../domain/Docusign/EDocusignButton";

const IBAN_ELEMENT_ID = "f3fbabe3-a187-4d8a-b401-5ca1f3e3b7d4"
const IBAN_1_ELEMENT_ID = "380a8c82-1efb-4339-a5d0-bff7de26112d"
const L_IBAN_ELEMENT_ID = "cef08ffb-f1ea-4a12-917b-1ca13aa22bd5"
const L2_IBAN_ELEMENT_ID = "66e39df8-aa9d-4534-9729-3e0f07bcd849"

const SUBSCRIPTION_TYPE_ELEMENT_ID = "06fca5c2-9279-11eb-a8b3-0242ac130003";
const SUBSCRIPTION_SIMPLE_TYPE = "Souscription simple";
const SUBSCRIPTION_DISMEMBERMENT_TYPE = "demembrement NP";
const SUBSCRIPTION_RECURRING_TYPE = "Souscription récurrente";

const SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID = "d35597e6-06b2-4aac-aade-e7e66e0eb17b";
const TEMPORARY_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Démembrement Temporaire";
const LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Démembrement en Viager";

const PRODUCT_SHARES_NUMBER_ELEMENT_ID = "06fcb274-9279-11eb-a8b3-0242ac130003";
const PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "06fcb332-9279-11eb-a8b3-0242ac130003";
const PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "06fcb526-9279-11eb-a8b3-0242ac130003";
const PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "06fcb5ee-9279-11eb-a8b3-0242ac130003";

const PRODUCT_CODE_FOR_EP = "EP";
const PRODUCT_CODE_FOR_IP = "IP";

const CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = "06fcafa4-9279-11eb-a8b3-0242ac130003";

// ................................................................................ ELEMENTS POUR LE DÉMEMBREMENT VIAGER

// ...................................................................................... Tableau de démembrement viager
const LIFE_DISMEMBERMENT_RATES_ELEMENT_ID = "027c92b4-a326-4cfd-8b83-e0e1efe1fb34"
// ................................................................................................ Age de l'Usufruitier
const LIFE_DISMEMBERMENT_AGE_ELEMENT_ID = "0f968665-7797-4aaf-a9d1-1aa493ea1e01"
// .................................................................................................... Taux Usufruitier
const LIFE_DISMEMBERMENT_USUFRUCT_RATE_ELEMENT_ID = "7153becc-3f25-4598-a118-9cb7261bc468"
// .................................................................................... Montant unitaire nu-propriétaire
const LIFE_DISMEMBERMENT_USUFRUCT_UNIT_AMOUNT_ELEMENT_ID = ""
// ................................................................................................. Montant Usufruitier
const LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_ELEMENT_ID = "ec379366-c2f3-4945-8a93-32ac853f3625"
// ............................................................................... Montant Usufruitier en toutes lettres
const LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"

// ................................................................................................ Taux nu-propriétaire
const LIFE_DISMEMBERMENT_BARE_OWNERSHIP_RATE_ELEMENT_ID = "b912d642-2742-4688-89a3-999335156a34"
// .................................................................................... Montant unitaire nu-propriétaire
const LIFE_DISMEMBERMENT_BARE_OWNERSHIP_UNIT_AMOUNT_ELEMENT_ID = "f12a415f-8a50-4951-ab97-2254342bf891"
// ............................................................................................. Montant nu-propriétaire
const LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID = "fe1de194-97ea-4a59-b0a2-1efb9b9d3178"
// ........................................................................... Montant nu-propriétaire en toutes lettres
const LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = "41171942-7972-4d9f-9506-c5329e6449b5"


// ............................................................................ ELEMENTS POUR LE DÉMEMBREMENT TEMPORAIRE

const TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "7904ee25-8ac6-499c-a179-119c3aae973d"
// ...................................................................................... Produit Voisin: Epargne Pierre
const TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID = "66bce220-e3a1-4284-82f0-2499a485e255"
// ...................................................................................... Produit Voisin: Immo Placement
const TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID = ""


export default class FetchVoisinMockedElementGateway {

    static get lifeDismembermentRateAgeElementId(): string {
        return LIFE_DISMEMBERMENT_AGE_ELEMENT_ID
    }

    static get lifeDismembermentUsufructAmountInLettersElementId(): string {
        return LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID
    }

    static get lifeDismembermentBareOwnershipAmountInLettersElementId(): string {
        return LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID
    }

    static get temporaryDismembermentRateDurationElementId(): string {
        return TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID
    }

    get(elements, productCode: string, formData): Promise<Element[] | null> {

        const subscriptionType = formData
            ? formData[SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID]
            : null

        const lifeDismemberment = (subscriptionType === LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)
        const temporaryDismemberment = (subscriptionType !== LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)

        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        lifeDismemberment && (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = LIFE_DISMEMBERMENT_RATES_ELEMENT_ID)

        switch (productCode) {
            case PRODUCT_CODE_FOR_EP:
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_IP:
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID)
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = (chosenProductUnitPriceElement !== undefined)
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (element) => {

                let type = element.type
                let attributes = element.attributes
                let condition = element.condition
                let calculate = ''
                let reference = ''

                switch (element.id) {

                    // .................................................... Bouton : Création, Authorisation du prospect
                    case "e176f745-ec12-49d3-92aa-0a8e5b63986c":
                        type = "button_create_prospect"
                        break

                    // ................................................................... Bouton : Imprimer le document
                    case "78979a0d-58be-4b03-95a4-02aa57317f8b":
                        type = EElementType.BUTTON_PRINT
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: false,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: 37
                        }
                        break

                    // ........................................... Bouton : Imprimer Lettre de mission (Parcours direct)
                    case  "071839f8-cb78-40d3-83f4-473354607f15":
                        type = EElementType.BUTTON_PRINT
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: false,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: 36
                        }
                        break

                    // .................................................. Bouton : Signer Entrée de relation (DER et LM)
                    case  "36401f4c-1908-420b-a8ff-f64c02433c0f":
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "80c9da5c-2517-47d3-9239-48b5db040aef" // MISSION_VALIDATION_DOCUSIGN_ID
                        }
                        break

                    // ......................... Bouton : Signer Entrée de relation pour le souscripteur (DER, LM et RI)
                    case  EDocusignButton.MISSION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "7cf81483-ad33-49e3-9e32-c8e72426b293" // SUBSCRIBER_ENTRY_RELATION_DOCUSIGN_ID
                        }
                        break

                    // ....................... Bouton : Signer Entrée de relation por le co-souscripteur (DER, LM et RI)
                    case  EDocusignButton.MISSION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "81f4d9d5-67bd-5617-a0d9-5fca6e1f1738"
                        }
                        break

                    // .............. Bouton : Signer Entrée de relation por le conjoint du souscripteur (DER, LM et RI)
                    case EDocusignButton.MISSION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                        /*type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "f4821dca-0c08-5ecb-a633-87da73134a5c" // SUBSCRIBER_SPOUSE_ENTRY_RELATION_DOCUSIGN_ID
                        }*/
                        break

                    //  .............................. Bouton : Signer les documents de soucription pour le souscripteur
                    case  EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "38c959b2-4109-4b8c-ae37-92e402ef19f7" // SUBSCRIBER_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ........................... Bouton : Signer les documents de souscription pour le co-souscripteur
                    case  EDocusignButton.SUBSCRIPTION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "36046765-8efd-4bb0-aba6-3d58bd4b268c" // CO_SUBSCRIBER_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // .................. Bouton : Signer les documents de souscription pour le conjoint du souscripteur
                    case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "53d3e6d9-0d46-4e93-8acb-4f68fec2a037" // SUBSCRIBER_SPOUSE_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // .... Bouton : Signer les documents de souscription pour cgp (conseiller en gestion de patrimoine)
                    case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_ADVISOR_SIGN_BUTTON_ELEMENT_ID:
                        type = EElementType.BUTTON_DOCUSIGN_IFRAME
                        attributes = {
                            required: false,
                            readonly: false,
                            disabled: attributes.disabled,
                            label: attributes?.label,
                            help: null,
                            errorMessage: null,
                            defaultValue: null,
                            docusignId: "6bf4e0e0-c366-4ed5-b896-356104a089c7" // CGP_CONFIRMATION_DOCUSIGN_ID
                        }
                        break

                    // ........................................................................................ Parcours
                    case "8a924722-bac6-4b3d-bfec-5080a0b4d8a1":
                        type = EElementType.PRESENTATION
                        reference = "693eb07f-b309-4677-90ab-1f9b39d1e92d"
                        break
                    // ............................................................................................. Nom
                    case "a3a12574-06e4-4a87-bfab-ed0de4c69ba2":
                        type = EElementType.PRESENTATION
                        reference = "06fc90c8-9279-11eb-a8b3-0242ac130003"
                        break
                    // .......................................................................................... Prénom
                    case "1ee4f512-ca1e-45b1-b712-0ec08699437b":
                        type = EElementType.PRESENTATION
                        reference = "06fc8e84-9279-11eb-a8b3-0242ac130003"
                        break
                    // ............................................................................... nom de la société
                    case "93b40cc0-a14a-46f7-9cdc-35d2937c8183":
                        type = EElementType.PRESENTATION
                        reference = "6033381f-e075-42fc-a234-0fcee9fc5f6c"
                        break

                    // .......................................................................................... Projet
                    case "4647b8a6-7549-4e7e-9f65-1da7245c9e13":
                        type = EElementType.PRESENTATION
                        reference = SUBSCRIPTION_TYPE_ELEMENT_ID
                        break

                    // ............................................................................................ SCPI
                    case "dbb5395d-cc20-4381-b7be-648c667f3d77":
                        type = EElementType.PRESENTATION
                        reference = "06fcae78-9279-11eb-a8b3-0242ac130003"
                        break

                    // ......................................................................................... Montant
                    case "de16fc41-67f0-4726-9bdf-b417561e420e":
                        type = EElementType.PRESENTATION
                        reference = PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    // ............................................................................ Type de démembrement
                    case "ed35c332-b76e-4d15-b7ab-ee38d16b5b17":
                        type = EElementType.PRESENTATION
                        reference = SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID
                        break

                    // ......................................................................................... Taux NP
                    case "f5293e3e-55c5-48d7-a57a-2b3a84562352":
                        type = EElementType.PRESENTATION
                        reference = "c415f4b9-cee3-4b21-9760-140f5e15a9dc"
                        break

                    // ........................................................................................ Taux USU
                    case "e66f2554-a3d2-4671-880f-56fbb894d86d":
                        type = EElementType.PRESENTATION
                        reference = "85d55bd0-d3e5-4d90-af44-fa78acc4821e"
                        break

                    // ..................................................................................... Montant USU
                    case "affe1c89-6add-4b55-832d-7a6d889388eb":
                        type = EElementType.PRESENTATION
                        reference = "aa7cb769-37c2-4171-b751-8e8f2475a3b6"
                        break

                    // ...................................................................................... Montant NP
                    case "f5212254-296a-4692-bb69-56925a1dfa24":
                        type = EElementType.PRESENTATION
                        reference = "f64a415f-8a50-4978-ab97-2254352bf891"
                        break

                    // ....................................................................................... Fréquence
                    case "ec0c250b-a61d-46d4-b484-c064459eb810":
                        type = EElementType.PRESENTATION
                        reference = "151908ad-c89d-4657-81a7-f0730cc69ae6"
                        break

                    // .............................................................................. Durée démembrement
                    case "75ea234d-0db6-41d5-ac1c-381b90cf1f8d":
                        type = EElementType.PRESENTATION
                        reference = "7904ee25-8ac6-499c-a179-119c3aae973d"
                        break

                    // ............................................................................... Mode de règlement
                    case "6b6a9c26-a178-4ae2-919a-7e6929d4c149":
                        type = EElementType.PRESENTATION
                        reference = "06fcbe54-9279-11eb-a8b3-0242ac130003"
                        break

                    // ...................................................................................... Montant NP
                    case "e705965a-0428-4d89-9fea-0ae36da7fd12":
                        type = EElementType.PRESENTATION
                        reference = "fe1de194-97ea-4a59-b0a2-1efb9b9d3178"
                        break

                    // ............................................................................. Montant Usufruitier
                    case "80a7f604-3bde-413b-8fde-b5cfa48e6242":
                        type = EElementType.PRESENTATION
                        reference = LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_ELEMENT_ID
                        break

                    // ......................................................................................... Taux NP
                    case "73efdfe1-fa54-4200-bb7e-b11960c1ed3e":
                        type = EElementType.PRESENTATION
                        reference = LIFE_DISMEMBERMENT_BARE_OWNERSHIP_RATE_ELEMENT_ID
                        break

                    // ................................................................................ Taux Usufruitier
                    case "6716fa5a-f474-4441-b933-f9c24428dd57":
                        type = EElementType.PRESENTATION
                        reference = LIFE_DISMEMBERMENT_USUFRUCT_RATE_ELEMENT_ID
                        break

                    case "c8c46ce8-088a-4e40-8946-08350cd94ae4":
                        type = EElementType.PRESENTATION
                        reference = `${PRODUCT_SHARES_NUMBER_ELEMENT_ID}`
                        break


                    // ................................................................ Justificatif d’origine des fonds
                    case "f0980926-dd3a-4838-b2c0-e6bf402733d1":
                        condition = {
                            rules: [
                                {
                                    type: "input",
                                    input_id: "06fcacfc-9279-11eb-a8b3-0242ac130003",
                                    value: "Oui"
                                },
                                {
                                    type: "input",
                                    input_id: PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID,
                                    value: 100000,
                                    operator: "greater_than"
                                }
                            ],
                            logical_operator: "or", "actions": {required: false, disabled: false}
                        }
                        break

                    // ............................................................................................ IBAN
                    case IBAN_ELEMENT_ID:
                    case IBAN_1_ELEMENT_ID:
                    case L_IBAN_ELEMENT_ID:
                    case L2_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // ............................................................................. DÉMEMBREMENT VIAGER


                    // ................................... H_montant-NP-dem-viager-toutes-lettres : ( en toutes lettres)
                    case LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_ELEMENT_ID
                        break
                    // ........................................................... Montant Usufruitier en toutes lettres
                    case LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_ELEMENT_ID
                        break


                    // ......................................................................... DÉMEMBREMENT TEMPORAIRE

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = `${PRODUCT_SHARES_NUMBER_ELEMENT_ID}`
                        break
                    // ............................................................ Tableau des taux Épargne Pierre (EP)
                    case TEMPORARY_DISMEMBERMENT_RATES_EP_RATES_ELEMENT_ID :
                        if (productCode !== PRODUCT_CODE_FOR_EP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ["Durée", "Usufruit", "Nue-propriété"],
                                options: [
                                    {
                                        value: "3",
                                        label: "3 ans",
                                        options: [{label: "13%", value: "13"}, {label: "87%", value: "87"}],
                                        presentation: "3 ans"
                                    },
                                    {
                                        value: "4",
                                        label: "4 ans",
                                        options: [{label: "17%", value: "17"}, {label: "83%", value: "83"}],
                                        presentation: "4 ans"
                                    },
                                    {
                                        value: "5",
                                        label: "5 ans",
                                        options: [{label: "21%", value: "21"}, {label: "79%", value: "79"}],
                                        presentation: "5 ans"
                                    },
                                    {
                                        value: "6",
                                        label: "6 ans",
                                        options: [{label: "24%", value: "24"}, {label: "76%", value: "76"}],
                                        presentation: "6 ans"
                                    },
                                    {
                                        value: "7",
                                        label: "7 ans",
                                        options: [{label: "27%", value: "27"}, {label: "73%", value: "73"}],
                                        presentation: "7 ans"
                                    },
                                    {
                                        value: "8",
                                        label: "8 ans",
                                        options: [{label: "29%", value: "29"}, {label: "71%", value: "71"}],
                                        presentation: "8 ans"
                                    },
                                    {
                                        value: "9",
                                        label: "9 ans",
                                        options: [{label: "32%", value: "32"}, {label: "68%", value: "68"}],
                                        presentation: "9 ans"
                                    },
                                    {
                                        value: "10",
                                        label: "10 ans",
                                        options: [{label: "34%", value: "34"}, {label: "66%", value: "66"}],
                                        presentation: "10 ans"
                                    },
                                    {
                                        value: "11",
                                        label: "11 ans",
                                        options: [{label: "35%", value: "35"}, {label: "65%", value: "65"}],
                                        presentation: "11 ans"
                                    },
                                    {
                                        value: "12",
                                        label: "12 ans",
                                        options: [{label: "36%", value: "36"}, {label: "64%", value: "64"}],
                                        presentation: "12 ans"
                                    },
                                    {
                                        value: "13",
                                        label: "13 ans",
                                        options: [{label: "37%", value: "37"}, {label: "63%", value: "63"}],
                                        presentation: "13 ans"
                                    },
                                    {
                                        value: "14",
                                        label: "14 ans",
                                        options: [{label: "38%", value: "38"}, {label: "62%", value: "62"}],
                                        presentation: "14 ans"
                                    },
                                    {
                                        value: "15",
                                        label: "15 ans",
                                        options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
                                        presentation: "15 ans"
                                    },
                                    {
                                        value: "16",
                                        label: "16 ans",
                                        options: [{label: "41%", value: "41"}, {label: "59%", value: "59"}],
                                        presentation: "16 ans"
                                    },
                                    {
                                        value: "17",
                                        label: "17 ans",
                                        options: [{label: "42%", value: "42"}, {label: "58%", value: "58"}],
                                        presentation: "17 ans"
                                    },
                                    {
                                        value: "18",
                                        label: "18 ans",
                                        options: [{label: "43%", value: "43"}, {label: "57%", value: "57"}],
                                        presentation: "18 ans"
                                    },
                                    {
                                        value: "19",
                                        label: "19 ans",
                                        options: [{label: "44%", value: "44"}, {label: "56%", value: "56"}],
                                        presentation: "19 ans"
                                    },
                                    {
                                        value: "20",
                                        label: "20 ans",
                                        options: [{label: "45%", value: "45"}, {label: "55%", value: "55"}],
                                        presentation: "20 ans"
                                    },
                                ]
                            }
                        }
                        break
                    // ....................................................... Tableau des taux Inter-Pierre France (IP)
                    case TEMPORARY_DISMEMBERMENT_RATES_IP_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_IP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement viager",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ["Durée", "Usufruit", "Nue-propriété"],
                                options: [
                                    {
                                        value: "3",
                                        label: "3 ans",
                                        options: [{label: "12.5%", value: "12.5"}, {
                                            label: "87.5%",
                                            value: "87.5"
                                        }],
                                        presentation: "3 ans"
                                    },
                                    {
                                        value: "4",
                                        label: "4 ans",
                                        options: [{label: "16%", value: "16"}, {label: "84%", value: "84"}],
                                        presentation: "4 ans"
                                    },
                                    {
                                        value: "5",
                                        label: "5 ans",
                                        options: [{label: "20%", value: "20"}, {label: "80%", value: "80"}],
                                        presentation: "5 ans"
                                    },
                                    {
                                        value: "6",
                                        label: "6 ans",
                                        options: [{label: "23.5%", value: "23.5"}, {
                                            label: "76.5%",
                                            value: "76.5"
                                        }],
                                        presentation: "6 ans"
                                    },
                                    {
                                        value: "7",
                                        label: "7 ans",
                                        options: [{label: "26%", value: "26"}, {label: "74%", value: "74"}],
                                        presentation: "7 ans"
                                    },
                                    {
                                        value: "8",
                                        label: "8 ans",
                                        options: [{label: "28.5%", value: "28.5"}, {
                                            label: "71.5%",
                                            value: "71.5"
                                        }],
                                        presentation: "8 ans"
                                    },
                                    {
                                        value: "9",
                                        label: "9 ans",
                                        options: [{label: "30%", value: "30"}, {label: "70%", value: "70"}],
                                        presentation: "9 ans"
                                    },
                                    {
                                        value: "10",
                                        label: "10 ans",
                                        options: [{label: "33%", value: "33"}, {label: "67%", value: "67"}],
                                        presentation: "10 ans"
                                    },
                                    {
                                        value: "11",
                                        label: "11 ans",
                                        options: [{label: "34.5%", value: "34.5"}, {
                                            label: "65.5%",
                                            value: "65.5"
                                        }],
                                        presentation: "11 ans"
                                    },
                                    {
                                        value: "12",
                                        label: "12 ans",
                                        options: [{label: "36%", value: "36"}, {label: "64%", value: "64"}],
                                        presentation: "12 ans"
                                    },
                                    {
                                        value: "13",
                                        label: "13 ans",
                                        options: [{label: "37%", value: "37"}, {label: "63%", value: "63"}],
                                        presentation: "13 ans"
                                    },
                                    {
                                        value: "14",
                                        label: "14 ans",
                                        options: [{label: "38%", value: "38"}, {label: "62%", value: "62"}],
                                        presentation: "14 ans"
                                    },
                                    {
                                        value: "15",
                                        label: "15 ans",
                                        options: [{label: "39%", value: "39"}, {label: "61%", value: "61"}],
                                        presentation: "15 ans"
                                    },
                                    {
                                        value: "16",
                                        label: "16 ans",
                                        options: [{label: "40%", value: "40"}, {label: "60%", value: "60"}],
                                        presentation: "16 ans"
                                    },
                                    {
                                        value: "17",
                                        label: "17 ans",
                                        options: [{label: "41%", value: "41"}, {label: "59%", value: "59"}],
                                        presentation: "17 ans"
                                    },
                                    {
                                        value: "18",
                                        label: "18 ans",
                                        options: [{label: "42%", value: "42"}, {label: "58%", value: "58"}],
                                        presentation: "18 ans"
                                    },
                                    {
                                        value: "19",
                                        label: "19 ans",
                                        options: [{label: "43%", value: "43"}, {label: "57%", value: "57"}],
                                        presentation: "19 ans"
                                    },
                                    {
                                        value: "20",
                                        label: "20 ans",
                                        options: [{label: "44%", value: "44"}, {label: "56%", value: "56"}],
                                        presentation: "20 ans"
                                    },
                                ]
                            }
                        }
                        break
                    // ............................................................................ Taux Nu-Propriétaire
                    case "c415f4b9-cee3-4b21-9760-140f5e15a9dc":
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break
                    // ................................................................ Montant Unitaire Nu-Propriétaire
                    case "f64a415f-8a50-4951-ab97-2254342bf891":
                        calculate = "(<input_id>c415f4b9-cee3-4b21-9760-140f5e15a9dc</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break
                    // ......................................................................... Montant Nu-Propriétaire
                    case "f64a415f-8a50-4978-ab97-2254352bf891":
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id> / 100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break
                    // ....................................................... Montant Nu-Propriétaire en toutes lettres
                    case "145b9e20-80b6-476b-ad4a-27a03b048abc":
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = "f64a415f-8a50-4978-ab97-2254352bf891"
                        break
                    // ................................................................................ Taux Usufruitier
                    case "85d55bd0-d3e5-4d90-af44-fa78acc4821e":
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break
                    // .................................................................... Montant Unitaire Usufruitier
                    case "f64a415f-8a50-5178-ab97-2254352bf891":
                        calculate = "(<input_id>85d55bd0-d3e5-4d90-af44-fa78acc4821e</input_id>"
                        calculate += ` / 100) * ${chosenProductUnitPrice}`
                        break
                    // ............................................................................. Montant Usufruitier
                    case "aa7cb769-37c2-4171-b751-8e8f2475a3b6":
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id> / 100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break
                    // .................................................................................. Montant global
                    // case PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                    //     calculate = `${chosenProductUnitPrice}`
                    //     calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                    //     break
                    // ................................................................ Montant global en toutes lettres
                    case PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break
                    // ........................................................................... Durée de démembrement
                    case TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    default:
                        break
                }
                return new Element(
                    element.id,
                    element.name,
                    type,
                    element.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )
    }

}
