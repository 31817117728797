import React, {FunctionComponent} from 'react';
import Element from '../../domain/Element/Element'

import FooterComponent from '../component/footer/FooterComponent';
import ArrayChoice from '../component/elements/array-choice/ArrayChoice';
import Calculate from '../component/elements/Calculate/Calculate';
import {IAttributes} from "../../domain/Element/ElementInterface";

const Ui: FunctionComponent = () => {

    const attributes: IAttributes = {
        label: "Total",
        readonly: false,
        disabled: true,
        required: true,
    };

    const element1 = new Element(
        'calculateelementtest1',
        'calculateelementtest1',
        'input_integer',
        '',
        attributes
    );

    const element2 = new Element(
        'calculateelementtest2',
        'calculateelementtest2',
        'input_integer',
        '',
        attributes
    );

    return (
        <div className="u-pbl">
            <div className="wrap u-mxAuto">
                <div className="flex-container u-mts">
                    <div className="col-md-12">
                        <h1>Component</h1>
                    </div>
                </div>
            </div>
            <div className="wrap wrap u-mxAuto">
                <div className="flex-container">
                    <ArrayChoice classes="col-md-12"
                                 options={{
                                     header: ['Age de l\'usufruitier', 'Usufruit', 'Nue-propriété'],
                                     options: [
                                         {
                                             value: '20',
                                             label: 'Moins de 20 ans révolus',
                                             options: [{label: '90%', value: '90'}, {label: '10%', value: '10'}],
                                             presentation: 'Moins de 20 ans révolus'
                                         },
                                         {
                                             value: '21_30',
                                             label: 'De 21 à 30 ans',
                                             options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                             presentation: 'De 21 à 30 ans'
                                         },
                                         {
                                             value: '31_40',
                                             label: 'De 31 à 40 ans',
                                             options: [{label: '70%', value: '70'}, {label: '30%', value: '30'}],
                                             presentation: 'De 31 à 40 ans'
                                         },
                                         {
                                             value: '41_50',
                                             label: 'De 41 à 50 ans',
                                             options: [{label: '60%', value: '60'}, {label: '40%', value: '40'}],
                                             presentation: 'De 41 à 50 ans'
                                         },
                                         {
                                             value: '51_60',
                                             label: 'De 51 à 60 ans',
                                             options: [{label: '50%', value: '50'}, {label: '50%', value: '50'}],
                                             presentation: 'De 51 à 60 ans'
                                         },
                                         {
                                             value: '61_70',
                                             label: 'De 61 à 70 ans',
                                             options: [{label: '40%', value: '40'}, {label: '60%', value: '60'}],
                                             presentation: 'De 61 à 70 ans'
                                         },
                                         {
                                             value: '71_80',
                                             label: 'De 71 à 80 ans',
                                             options: [{label: '30%', value: '30'}, {label: '70%', value: '70'}],
                                             presentation: 'De 71 à 80 ans'
                                         },
                                         {
                                             value: '81_90',
                                             label: 'De 81 à 90 ans',
                                             options: [{label: '20%', value: '20'}, {label: '80%', value: '80'}],
                                             presentation: 'De 81 à 90 ans'
                                         },
                                         {
                                             value: '91',
                                             label: 'Plus de 91 ans',
                                             options: [{label: '10%', value: '10'}, {label: '90%', value: '90'}],
                                             presentation: 'Plus de 91 ans'
                                         },
                                     ]
                                 }}
                                 label="Tableau d'amortissement"
                                 id="ceciestunid"
                                 name="Ceci est un nom"/>
                    <ArrayChoice classes="col-md-12"
                                 options={{
                                     header: ['Année', 'Usufruit', 'Nue-propriété'],
                                     options: [
                                         {
                                             value: '3',
                                             label: '3 ans',
                                             options: [{label: '14%', value: '14'}, {label: '86%', value: '86'}],
                                             presentation: '3 ans'
                                         },
                                         {
                                             value: '4',
                                             label: '4 ans',
                                             options: [{label: '18%', value: '18'}, {label: '82%', value: '82'}],
                                             presentation: '4 ans'
                                         },
                                         {
                                             value: '5',
                                             label: '5 ans',
                                             options: [{label: '22%', value: '22'}, {label: '78%', value: '78'}],
                                             presentation: '5 ans'
                                         },
                                         {
                                             value: '6',
                                             label: '6 ans',
                                             options: [{label: '25%', value: '25'}, {label: '75%', value: '75'}],
                                             presentation: '6 ans'
                                         },
                                         {
                                             value: '7',
                                             label: '7 ans',
                                             options: [{label: '28%', value: '28'}, {label: '72%', value: '72'}],
                                             presentation: '7 ans'
                                         },
                                         {
                                             value: '8',
                                             label: '8 ans',
                                             options: [{label: '30%', value: '30'}, {label: '70%', value: '70'}],
                                             presentation: '8 ans'
                                         },
                                         {
                                             value: '9',
                                             label: '9 ans',
                                             options: [{label: '33%', value: '33'}, {label: '67%', value: '67'}],
                                             presentation: '9 ans'
                                         },
                                         {
                                             value: '10',
                                             label: '10 ans',
                                             options: [{label: '35%', value: '35'}, {label: '65%', value: '65'}],
                                             presentation: '10 ans'
                                         },
                                         {
                                             value: '11',
                                             label: '11 ans',
                                             options: [{label: '37%', value: '37'}, {label: '63%', value: '63'}],
                                             presentation: '11 ans'
                                         },
                                         {
                                             value: '12',
                                             label: '12 ans',
                                             options: [{label: '39%', value: '39'}, {label: '61%', value: '61'}],
                                             presentation: '12 ans'
                                         },
                                         {
                                             value: '13',
                                             label: '13 ans',
                                             options: [{label: '41%', value: '41'}, {label: '59%', value: '59'}],
                                             presentation: '13 ans'
                                         },
                                         {
                                             value: '14',
                                             label: '14 ans',
                                             options: [{label: '42%', value: '42'}, {label: '58%', value: '58'}],
                                             presentation: '14 ans'
                                         },
                                         {
                                             value: '15',
                                             label: '15 ans',
                                             options: [{label: '43%', value: '43'}, {label: '57%', value: '57'}],
                                             presentation: '15 ans'
                                         },
                                     ]
                                 }}
                                 label="Tableau d'amortissement"
                                 id="ceciestunid"
                                 name="Ceci est un nom"/>
                    {/*(v1*100/v2)*/}
                    <Calculate element={element1}
                               clearErrors={() => {
                                   return
                               }}
                               formData={[]}
                               register={undefined}
                    />
                    <Calculate element={element2}
                               clearErrors={() => {
                                   return
                               }}
                               formData={[]}
                               register={undefined}
                    />
                </div>
            </div>
            <FooterComponent/>
        </div>
    )
};
export default Ui;
