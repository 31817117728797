import Element from "../../domain/Element/Element";
import {EElementType} from "../../domain/Element/EElementType";

const SWISSLIFE_IBAN_ELEMENT_ID = "3ef2c63b-776f-43df-871d-4e11e0b4f738";
const SWISSLIFE_SHARES_NUMBER_ID = "4009c05a-e422-4834-8186-54fa85c5919b";
const SWISSLIFE_SHARES_NUMBER_IN_LETTERS_ID = "860c03b0-572d-4e2d-8061-87fef6bb9677";
const SWISSLIFE_TOTAL_AMOUNT_ID = "e3562c0b-63d9-4ef8-a4c4-7642b81224f7";
const SWISSLIFE_TOTAL_AMOUNT_IN_LETTERS_ID = "f96311bb-b8f1-43ab-9196-39ef85341563";
const SWISSLIFE_UNIT_PRICE_ID = "1dc2d45d-467d-4aae-95e1-70fea9943096";

export default class FetchSwisslifeMockedElementGateway{

    get(elements, productCode: string, formData): Promise<Element[] | null> {
        return elements.map(
            (item) => {

                let type = item.type
                const attributes = item.attributes
                const condition = item.condition
                let calculate = ""
                let reference = ""

                switch (item.id) {

                    // .................................................................................... IBAN
                    case SWISSLIFE_IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break
                    case SWISSLIFE_TOTAL_AMOUNT_ID:
                        calculate = `<input_id>${SWISSLIFE_SHARES_NUMBER_ID}</input_id>`
                        calculate += `* <input_id>${SWISSLIFE_UNIT_PRICE_ID}</input_id>`
                        break
                    case SWISSLIFE_SHARES_NUMBER_IN_LETTERS_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD;
                        reference = SWISSLIFE_SHARES_NUMBER_ID;
                        break
                    case SWISSLIFE_TOTAL_AMOUNT_IN_LETTERS_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD;
                        reference = SWISSLIFE_TOTAL_AMOUNT_ID;
                        break
                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )
    }

}