import FormGatewayInterface from '../../Form/FormGatewayInterface';

function calculate(fn) {
  return new Function('return ' + fn)();
}

export default class CalculateUseCase {
  public formGateway: FormGatewayInterface

  constructor(FormGateway: FormGatewayInterface) {
    this.formGateway = FormGateway
  }

  execute(mathematicalFormula: string | undefined): number | null {
    if (undefined === mathematicalFormula) {
      return null
    }

    const data = this.formGateway.getCurrentValueForFormId()

    const inputIds = mathematicalFormula.match(/<input_id>(.*?)<\/input_id>/g)

    if (null === inputIds) {
      return null
    }

    let formula = mathematicalFormula
    inputIds.map(id => {
      const idWithoutTag = id.replaceAll(/<\/?input_id>/g,'')
      let valueFromFormData = data.values[idWithoutTag]
      console.log("valueFromFormData",valueFromFormData)
      if (valueFromFormData == undefined) {
        const documentElements = document.getElementsByName(idWithoutTag)
        console.log("documentElements",documentElements)
        documentElements.length > 0
        && !documentElements[0].hasChildNodes()
        && (valueFromFormData = documentElements[0].nodeValue )
      }

      formula = formula.replace(`<input_id>${idWithoutTag}</input_id>`, valueFromFormData)
    })

    console.log("formula", formula)

    try {
      const result = calculate(formula)
      return (Number(result)) ? Number(parseFloat(result).toPrecision(12)) : null
    } catch (e) {
      console.log(e)
      return null
    }
  }
}
