import Element from "../../domain/Element/Element";
import {EElementType} from "../../domain/Element/EElementType";

const IBAN_ELEMENT_ID = "3ef2c63b-776f-43df-871d-4e11e0b4f738";

const SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID = "5fb29627-89c9-4a69-9479-70eaf510ab16";
const FULL_OWNERSHIP_DISMEMBERMENT_SUBSCRIPTION_DETENTION_MODE = "Pleine propriété";
const KNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie connue";
const UNKNOWN_COUNTERPART_SUBSCRIPTION_DETENTION_MODE = "contrepartie inconnue";

const PRODUCT_SHARES_NUMBER_ELEMENT_ID = "4009c05a-e422-4834-8186-54fa85c5919b";
const PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID = "860c03b0-572d-4e2d-8061-87fef6bb9677";
const PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID = "e3562c0b-63d9-4ef8-a4c4-7642b81224f7";
const PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID = "f96311bb-b8f1-43ab-9196-39ef85341563";

const DEM_TEMP_USU_ELEMENT_ID = "f64a415f-8a50-5178-ab97-2254352bf891";
const DEM_TEMP_NP_ELEMENT_ID = "f64a415f-8a50-4978-ab97-2254352bf891";

/**
 * @todo Product should be set as of "public.produit.code_produit"
 * UPDATE subscription.option SET value = 'LI' WHERE option_id = '6ae60166-162a-4c2e-8d6a-f6ff0c6d83fc'
 */

const PRODUCT_CODE_FOR_LI = "LI"; // ................................................................ REMAKE LIVE
const PRODUCT_CODE_FOR_MS = "MS"; // ........................................................... MA PREMIERE SCPI
const PRODUCT_CODE_FOR_DS = "DS"; // ............................................................ MA SECONDE SCPI
const PRODUCT_CODE_FOR_IR = "IR"; // ....................................................................... IMMO
const PRODUCT_CODE_FOR_HP = "HP"; // ................................................................... PIERRE 1
const PRODUCT_CODE_FOR_AL = "AL"; // ...................................................................... SCPI1
const PRODUCT_CODE_FOR_EF = "EF"; // ...................................................................... EFI 3

const LI_PART_UNIT_PRICE_ELEMENT_ID = "1dc2d45d-467d-4aae-95e1-70fea9943096";
const MS_PART_UNIT_PRICE_ELEMENT_ID = "";
const DS_PART_UNIT_PRICE_ELEMENT_ID = "";
const IR_PART_UNIT_PRICE_ELEMENT_ID = "";
const HP_PART_UNIT_PRICE_ELEMENT_ID = "";
const AL_PART_UNIT_PRICE_ELEMENT_ID = "";
const EF_PART_UNIT_PRICE_ELEMENT_ID = "";

const SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID = "878934b9-8a9d-4516-8d20-b9cb69abde02";
const TEMPORARY_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Temporaire";
const LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE = "Viager";

const LIFE_DISMEMBERMENT_RATES_ELEMENT_ID = "2e86f90e-5d7d-45ef-84e6-2de30bd44d12"
const LIFE_DISMEMBERMENT_AGE_ELEMENT_ID = ""
const LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = ""
const LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = ""

const TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID = "0ced93a2-a8f5-4c43-8df6-2eca7fa4a3d8"

const TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID = "1053eda7-28ea-46a3-8175-9f5f4b1d395a"
const TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID = ""
const TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID = ""
const TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID = ""
const TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID = ""
const TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID = ""
const TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID = ""

const BARE_OWNERSHIP_RATE_ELEMENT_ID = "5d545b60-4bd2-49a7-b92d-bbf04ba7c312"
const BARE_OWNERSHIP_AMOUNT_ELEMENT_ID = "89386a85-e0a3-4079-8062-c2232a15bc07"
const BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID = "41171942-7972-4d9f-9506-c5329e6449b5"

const USUFRUCT_RATE_ELEMENT_ID = "acdde060-5f73-4872-8189-ef0abe90721c"
const USUFRUCT_AMOUNT_ELEMENT_ID = "df0123b1-b315-4603-a564-e9ab7469b3c1"
const USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID = "68f0c2ca-2a0c-4f3f-a19b-4cd30dd814ed"

export default class FetchRemakeMockedElementGateway {

    static get lifeDismembermentRateAgeElementId(): string {
        return LIFE_DISMEMBERMENT_AGE_ELEMENT_ID
    }

    static get lifeDismembermentUsufructAmountInLettersElementId(): string {
        return LIFE_DISMEMBERMENT_USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID
    }

    static get lifeDismembermentBareOwnershipAmountInLettersElementId(): string {
        return LIFE_DISMEMBERMENT_BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID
    }

    static get temporaryDismembermentRateDurationElementId(): string {
        return TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID
    }

    get(elements, productCode: string, formData): Promise<Element[] | null> {

        const remakeSubscriptionType = formData
            ? formData[SUBSCRIPTION_DISMEMBERMENT_TYPE_ELEMENT_ID]
            : null

        const remakeSubscriptionDetentionMode = formData
            ? formData[SUBSCRIPTION_DETENTION_MODE_ELEMENT_ID]
            : null

        const lifeDismemberment = (remakeSubscriptionType === LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)
        const temporaryDismemberment = (remakeSubscriptionType !== LIFE_DISMEMBERMENT_SUBSCRIPTION_TYPE)

        let CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = ''
        let CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = ''

        lifeDismemberment && (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = LIFE_DISMEMBERMENT_RATES_ELEMENT_ID)

        switch (productCode) {
            case PRODUCT_CODE_FOR_LI:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = LI_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_MS:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = MS_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_DS:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = DS_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_IR:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = IR_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_HP:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = HP_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_AL:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = AL_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID)
                break
            case PRODUCT_CODE_FOR_EF:
                CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID = EF_PART_UNIT_PRICE_ELEMENT_ID
                temporaryDismemberment &&
                (CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID = TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID)
                break
            default:
        }

        const chosenProductUnitPriceElement = elements.find(
            (element) => element.id === CHOSEN_PRODUCT_PART_UNIT_PRICE_ELEMENT_ID
        )

        const chosenProductUnitPrice = chosenProductUnitPriceElement !== undefined
            ? chosenProductUnitPriceElement.attributes.defaultValue
            : ''

        return elements.map(
            (item) => {

                const condition = item.condition
                let type = item.type
                let attributes = item.attributes
                let calculate = ''
                let reference = ''

                switch (item.id) {

                    // ............................................................................................ IBAN
                    case IBAN_ELEMENT_ID:
                        type = EElementType.INPUT_IBAN
                        break

                    // ...................................................................... Tableau de démembrement LI
                    case TEMPORARY_DISMEMBERMENT_LI_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_LI) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement DS
                    case TEMPORARY_DISMEMBERMENT_DS_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_DS) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement MS
                    case TEMPORARY_DISMEMBERMENT_MS_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_MS) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement IR
                    case TEMPORARY_DISMEMBERMENT_IR_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_IR) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement HP
                    case TEMPORARY_DISMEMBERMENT_HP_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_HP) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement AL
                    case TEMPORARY_DISMEMBERMENT_AL_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_AL) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ...................................................................... Tableau de démembrement EF
                    case TEMPORARY_DISMEMBERMENT_EF_RATES_ELEMENT_ID:
                        if (productCode !== PRODUCT_CODE_FOR_EF) {
                            break
                        }
                        type = EElementType.ARRAY_CHOICE
                        attributes = {
                            variant: "temporary_dismemberment",
                            label: "Tableau de démembrement temporaire",
                            readonly: false,
                            disabled: true,
                            required: true,
                            multiple: false,
                            options: {
                                header: ['Durée', 'Nue-propriété', 'Usufruit'],
                                options: [
                                    {
                                        value: '5',
                                        label: '5 ans',
                                        options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}],
                                        presentation: '5 ans'
                                    },
                                    {
                                        value: '6',
                                        label: '6 ans',
                                        options: [{label: '77%', value: '77'}, {label: '23%', value: '23'}],
                                        presentation: '6 ans'
                                    },
                                    {
                                        value: '7',
                                        label: '7 ans',
                                        options: [{label: '74%', value: '74'}, {label: '26%', value: '26'}],
                                        presentation: '7 ans'
                                    },
                                    {
                                        value: '8',
                                        label: '8 ans',
                                        options: [{label: '72%', value: '72'}, {label: '28%', value: '28'}],
                                        presentation: '8 ans'
                                    },
                                    {
                                        value: '9',
                                        label: '9 ans',
                                        options: [{label: '69%', value: '69'}, {label: '31%', value: '31'}],
                                        presentation: '9 ans'
                                    },
                                    {
                                        value: '10',
                                        label: '10 ans',
                                        options: [{label: '67%', value: '67'}, {label: '33%', value: '33'}],
                                        presentation: '10 ans'
                                    },
                                    {
                                        value: '11',
                                        label: '11 ans',
                                        options: [{label: '66,5%', value: '66.5'}, {label: '33,5%', value: '33.5'}],
                                        presentation: '11 ans'
                                    },
                                    {
                                        value: '12',
                                        label: '12 ans',
                                        options: [{label: '65,5%', value: '65.5'}, {label: '34,5%', value: '34.5'}],
                                        presentation: '12 ans'
                                    },
                                    {
                                        value: '13',
                                        label: '13 ans',
                                        options: [{label: '63,5%', value: '63.5'}, {label: '36,5%', value: '36.5'}],
                                        presentation: '13 ans'
                                    },
                                    {
                                        value: '14',
                                        label: '14 ans',
                                        options: [{label: '62,5%', value: '62.5'}, {label: '37,5%', value: '37.5'}],
                                        presentation: '14 ans'
                                    },
                                    {
                                        value: '15',
                                        label: '15 ans',
                                        options: [{label: '61,5%', value: '61.5'}, {label: '38,5%', value: '38.5'}],
                                        presentation: '15 ans'
                                    },
                                    {
                                        value: '16',
                                        label: '16 ans',
                                        options: [{label: '60,5%', value: '60.5'}, {label: '39,5%', value: '39.5'}],
                                        presentation: '16 ans'
                                    },
                                    {
                                        value: '17',
                                        label: '17 ans',
                                        options: [{label: '59,5%', value: '59.5'}, {label: '40,5%', value: '40.5'}],
                                        presentation: '17 ans'
                                    },
                                    {
                                        value: '18',
                                        label: '18 ans',
                                        options: [{label: '58,5%', value: '58.5'}, {label: '41,5%', value: '41.5'}],
                                        presentation: '18 ans'
                                    },
                                    {
                                        value: '19',
                                        label: '19 ans',
                                        options: [{label: '57,5%', value: '57.5'}, {label: '42,5%', value: '42.5'}],
                                        presentation: '19 ans'
                                    },
                                    {
                                        value: '20',
                                        label: '20 ans',
                                        options: [{label: '56,5%', value: '56.5'}, {label: '43,5%', value: '43.5'}],
                                        presentation: '20 ans'
                                    },
                                ],
                            },
                        }
                        break

                    // ............................................................. Nombre de parts (en toutes lettres)
                    case PRODUCT_SHARES_NUMBER_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = PRODUCT_SHARES_NUMBER_ELEMENT_ID
                        break

                    // ............................................................................ Taux Nu-Propriétaire
                    case BARE_OWNERSHIP_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id>`
                        break

                    // ......................................................................... Montant Nu-Propriétaire
                    case BARE_OWNERSHIP_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_0</input_id> /100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // .................................................. Montant Nu-Propriétaire : ( en toutes lettres)
                    case BARE_OWNERSHIP_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = BARE_OWNERSHIP_AMOUNT_ELEMENT_ID
                        break

                    // ................................................................................ Taux Usufruitier
                    case USUFRUCT_RATE_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id>`
                        break

                    // ............................................................................. Montant Usufruitier
                    case USUFRUCT_AMOUNT_ELEMENT_ID:
                        calculate = `(<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}_1</input_id> / 100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ...................................................... Montant Usufruitier : ( en toutes lettres)
                    case USUFRUCT_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = USUFRUCT_AMOUNT_ELEMENT_ID
                        break

                    // ........................................................................... Durée de démembrement
                    case TEMPORARY_DISMEMBERMENT_DURATION_ELEMENT_ID:
                        calculate = `<input_id>${CHOSEN_DISMEMBERMENT_RATES_ELEMENT_ID}</input_id>`
                        break

                    // .................................................................................. Montant global
                    case PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID:
                        calculate = `${chosenProductUnitPrice}`
                        calculate += ` * <input_id>${PRODUCT_SHARES_NUMBER_ELEMENT_ID}</input_id>`
                        break

                    // ...................................... Démembrement temporaire : Montant Unitaire Nu-Propriétaire
                    case DEM_TEMP_NP_ELEMENT_ID:
                        calculate = `(<input_id>${BARE_OWNERSHIP_RATE_ELEMENT_ID}</input_id> / 100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        break

                    // .................................................. Démembrement temporaire : Montant Unitaire Usu
                    case DEM_TEMP_USU_ELEMENT_ID:
                        calculate = `(<input_id>${USUFRUCT_RATE_ELEMENT_ID}</input_id> / 100)`
                        calculate += ` * ${chosenProductUnitPrice}`
                        break

                    // ........................................................... Montant global : ( en toutes lettres)
                    case PRODUCT_GLOBAL_AMOUNT_IN_LETTERS_ELEMENT_ID:
                        type = EElementType.INPUT_CONVERT_NUMBER_TO_WORD
                        reference = PRODUCT_GLOBAL_AMOUNT_ELEMENT_ID
                        break

                    default:
                        break
                }

                return new Element(
                    item.id,
                    item.name,
                    type,
                    item.css_classes,
                    attributes,
                    condition,
                    calculate,
                    reference
                )
            }
        )

    }

}